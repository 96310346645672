import React from 'react'

//CSS
import './About.css'

import img_desktop from '../../Assets/Images/1440x782/banner_about.jpg'
import img_tablet from '../../Assets/Images/1600x900/Nosotros.jpg'
import img_mobile from '../../Assets/Images/1080x920/Nosotros.jpg'
import img_1 from '../../Assets/Images/1440x782/img_5.jpg'
import about from '../../Assets/Images/1440x782/Nosotros 2.png'
import inv from '../../Assets/Images/Innovacion-y-ciencia.png'
import acom from '../../Assets/Images/acompañamiento-siempre-profesional.png'
import sol from '../../Assets/Images/soluciones-efectivas-a-tu-alcance.png'
import enf from '../../Assets/Images/enfoque-en-calidad-y-seguridad.png'

//MUI
import { Box, Container, Grid, Typography } from '@mui/material'

//Components
import InvClinic from '../Experts/InvClinic/InvClinic'
import DesClinic from '../Experts/DesClinic/DesClinic'
import NutriClinic from '../Experts/NutriClinic/NutriClinic'

const About = () => {

  return (
    <>

      <div className="position-relative-about">
        <Box>
          <div className='banner-about-desktop'>
            <img src={img_desktop} alt={img_desktop} width='100%' />
          </div>

          <div className='banner-about-tablet'>
            <img src={img_tablet} alt={img_tablet} width='100%' />
          </div>

          <div className='banner-about-mobile'>
            <img src={img_mobile} alt={img_mobile} width='100%' />
          </div>
        </Box>

        <div className="position-absolute-about image-container-about">
          <div className='card_floating-about'></div>
        </div>
      </div>

      <Container maxWidth='xxl' id='about_page'>
        <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900', mb: 4 }}>Nuestras competencias:</Typography>
        <Grid container spacing={4}>
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
            <Box p={3} className='competencies_card'>
              <div className='competencies_card_header'>
                <img src={inv} alt={inv} width='55' height='55' style={{ objectFit: 'contain' }} />
                <Typography paragraph
                  sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                >
                  Innovación y ciencia
                </Typography>
              </div>
              <Typography variant='body1'>
                A lo largo de 15 años, hemos estado a la vanguardia de soluciones eficaces en salud y nutrición.
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
            <Box p={3} className='competencies_card'>
              <div className='competencies_card_header'>
                <img src={acom} alt={acom} width='55' height='55' style={{ objectFit: 'contain' }} />
                <Typography paragraph
                  sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                >
                  Acompañamiento siempre profesional
                </Typography>
              </div>
              <Typography variant='body1'>
                Nuestro equipo está comprometido a brindar un acompañamiento profesional en todo momento. Desde la
                conceptualización hasta la comercialización.
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
            <Box p={3} className='competencies_card'>
              <div className='competencies_card_header'>
                <img src={sol} alt={sol} width='55' height='55' style={{ objectFit: 'contain' }} />
                <Typography paragraph
                  sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                >
                  Soluciones efectivas a tu alcance
                </Typography>
              </div>
              <Typography variant='body1'>
                Con una diversa gama de productos y servicios, nos esforzamos por ofrecer opciones que sean efectivas
                y asequibles, sin comprometer la calidad
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={3} lg={6} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
            <Box p={3} className='competencies_card'>
              <div className='competencies_card_header'>
                <img src={enf} alt={enf} width='55' height='55' style={{ objectFit: 'contain' }} />
                <Typography paragraph
                  sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                >
                  Enfoque en calidad y seguridad
                </Typography>
              </div>
              <Typography variant='body1'>
                Priorizamos la excelencia y la integridad en todo lo que hacemos, garantizando la tranquilidad y la confianza
                de nuestros clientes y aliados comerciales
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth='xl' sx={{ mb: 6 }}>
        <Box sx={{ my: 6 }}>
          <img src={img_1} alt={img_1} width='100%' className='about_img_1' />
        </Box>
        <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }}>Nuestros compromisos:</Typography>
        <ul>
          <li style={{ marginBottom: '15px' }}>
            Asumimos la responsabilidad de resolver cualquier desafío que se presente.
          </li>
          <li style={{ marginBottom: '15px' }}>
            Nuestro enfoque proactivo nos permite preveer y resolver siempre de manera eficiente, garantizando un servicio sin contratiempos
            y resultados excepcionales.
          </li>
          <li style={{ marginBottom: '15px' }}>
            Valoramos profundamente las perspectivas y necesidades de nuestro entorno, por lo que nos esforzamos por comprender cada situación
            de forma única y proporcionar soluciones que se adapten a los requerimientos específicos.
          </li>
          <li style={{ marginBottom: '15px' }}>
            La pasión impulsa nuestro trabajo. Cada proyecto es una oportunidad para demostrar nuestro amor por lo que hacemos y para superar
            continuamente nuestras propias expectativas.
          </li>
          <li style={{ marginBottom: '15px' }}>
            Nos comprometemos a desafiarnos constantemente, a aprender de cada experiencia y a mejorar continuamente nuestros métodos y servicios
            para superar tus expectativas.
          </li>
          <li>
            Nos regimos por los más altos estándares éticos y morales. Siempre nos esforzamos por hacer lo correcto.
          </li>
        </ul>
        <Box sx={{ my: 6 }}>
          <img src={about} alt={about} width='100%' />
        </Box>
        <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }}>Nuestros expertos:</Typography>
        <Typography variant='body1'>
          Logramos la excelencia gracias a los expertos que con su dedicación y experiencia colaboran con nosotros.
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant='body1' paragraph>
            <b style={{ color: '#00CE91' }}>Equipo de Investigación Clínica:</b> contamos con un equipo altamente cualificado comprometidos con la vanguardia de la investigación médica.
          </Typography>
          <InvClinic />
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant='body1' paragraph>
            <b style={{ color: '#00CE91' }}>Equipo de Desarrollo de Productos:</b> formado por mentes creativas y expertos técnicos que trabajan en estrecha colaboración para convertir ideas innovadoras en soluciones tangibles.
          </Typography>
          <DesClinic />
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant='body1' paragraph>
            <b style={{ color: '#00CE91' }}>Equipo de Nutrición Clínica:</b> Nuestros expertos en nutrición trabajan para brindar soluciones integrales y basadas en evidencia para optimizar la salud y el rendimiento.
          </Typography>
          <NutriClinic />
        </Box>
      </Container>
    </>
  )
}

export default About