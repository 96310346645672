import React from 'react'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';

//Components
import Experts from './Experts'
import { Box } from '@mui/material';

const ExpertsList = ({ data }) => {
    return (
        <Box className='experts_swiper'>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 1,
                    },
                    700: {
                        slidesPerView: 2,
                    },
                    1000: {
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={40}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                loop={true}
                modules={[Pagination]}
            >
                {data.map((item) => (
                    <SwiperSlide key={item.id}>
                        <Experts item={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    )
}

export default ExpertsList