import React, { useEffect, useRef, useState } from 'react'

//Images
import simbin_img from '../../../Assets/Images/simbin_rnl_60caps.jpg'

//Informes
import simbin_inf from '../../../Assets/Resources/Informes/Probiotics-in-septic-acute-kidney-injury-a-double-blind-randomized-control-trial.pdf'

//MUI
import {
    Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel,
    FormHelperText, Grid, IconButton, InputLabel, List, MenuItem, Select, TextField, Typography, useMediaQuery, Link as MUILink
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';

//Router dom
import { Link } from 'react-router-dom';

//Request
import { saveFormDataInf } from '../../../requests/form';

const infCie = [
    {
        id: 1,
        name: 'Simbin caps',
        img: simbin_img,
        protocol: simbin_inf,
        date: 'Octubre 23, 2024'
    },
];

const specialtiesArray = [
    'Nefrología', 'Nutriología', 'Urología', 'Cardgiología', 'Endocrinología', 'Otro'
];

const init = {
    especialidad: '',
    especialidad2: '',
    email: '',
    terms: ''
};

const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const initErr = {
    esp_err: false,
    esp2_err: false,
    email_err: false,
    terms_err: false
};

const InformesCientificos = () => {

    const matches = useMediaQuery('(max-width: 506px)')

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const [user, setUser] = useState(init);
    const { especialidad, especialidad2, email, terms } = user;

    const [inputErr, setInputErr] = useState(initErr);
    const { esp_err, esp2_err, email_err, terms_err } = inputErr;

    const [action, setAction] = useState(true);

    const esp_ref = useRef();
    const esp2_ref = useRef();
    const email_ref = useRef();

    useEffect(() => {
        if (open) {
            const form_email = localStorage.getItem('form_email_inf');
            if (form_email) {
                setUser(e => ({ ...e, email: form_email }));
                setAction(false);
            } else
                setAction(true);
        }
    }, [open]);

    const onChange = ({ target }) => {
        const name = target.name
        const value = target.value;
        setUser(e => ({
            ...e,
            [name]: value
        }));
        switch (name) {
            case 'especialidad':
                setInputErr({
                    ...inputErr,
                    esp_err: (value.length < 2 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'especialidad2':
                setInputErr({
                    ...inputErr,
                    esp2_err: (value.length < 2 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 120) ? true : false
                });
                break;
            default:
                break;
        };
    };

    const onCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setUser(e => ({
            ...e,
            terms: checked
        }));
        switch (e.target.name) {
            case 'terms':
                setInputErr({
                    ...inputErr,
                    terms_err: (value === false) ? true : false
                });
                break;
            default:
                break;
        }
    };

    const formValidator = () => {
        const esp_len = especialidad.trim().length;
        const esp2_len = especialidad2.trim().length;
        const email_len = email.trim().length;
        if (esp_len > 2 && esp_len <= 60) {
            if (especialidad === 'Otro') {
                if (esp2_len > 2 && esp2_len <= 60)
                    if (email_len > 5 && email_len <= 120 && email_regex.test(email)) {
                        if (terms === true) {
                            return true
                        } else {
                            setInputErr({
                                ...inputErr,
                                terms_err: true
                            });
                        }
                    } else {
                        email_ref.current.select();
                        setInputErr({
                            ...inputErr,
                            email_err: true
                        });
                    }
                else
                    setInputErr({
                        ...inputErr,
                        esp2_err: true
                    });
            } else {
                if (email_len > 5 && email_len <= 120 && email_regex.test(email)) {
                    if (terms === true) {
                        return true
                    } else {
                        setInputErr({
                            ...inputErr,
                            terms_err: true
                        });
                    }
                } else {
                    email_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        email_err: true
                    });
                }
            };
        } else {
            setInputErr({
                ...inputErr,
                esp_err: true
            });
        }
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formValidator()) {
            setOpen(false);
            await saveFormDataInf({ ...user, protocol: modalData.name });
            setUser(init);
            //Descargar PDF
            // const pdfUrl = modalData.protocol;
            // const link = document.createElement("a");
            // link.href = pdfUrl;
            // link.download = `Protocolo ${modalData.name}.pdf`;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            //Sólo visualizar pdf
            const link = document.createElement("a");
            link.setAttribute("href", modalData.protocol);
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
            document.body.appendChild(link);
            setTimeout(() => {
                link.click();
                document.body.removeChild(link);
            }, 70);
        }
    };

    return (
        <>
            <Typography paragraph sx={{ fontWeight: '600', fontSize: '28px' }}>Informes cientificos:</Typography>
            <List sx={{ width: '100%' }}>
                {
                    infCie.map((data) => (
                        <div key={data.id}>
                            <Box className='protocol_container'>
                                <Box className='protocol_img_name'>
                                    <Box sx={{ my: 'auto' }}>
                                        <img src={data.img} alt={data.img} width='80px' style={{ pointerEvents: 'none' }} />
                                    </Box>
                                    <Box sx={{ my: 'auto' }}>
                                        <Typography variant='body1' sx={{ fontWeight: '600' }}>{`${data.id}. Informe cientifico ${data.name}`}</Typography>
                                        <Typography variant='body1' color='text.secondary'>Fecha de publicación: {data.date}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    {
                                        matches
                                            ?
                                            <Button
                                                variant='outlined'
                                                sx={{ textTransform: 'none' }}
                                                endIcon={<DownloadIcon />}
                                                onClick={() => {
                                                    setModalData(data);
                                                    setOpen(true);
                                                }}
                                            >
                                                Descargar informe
                                            </Button>
                                            :
                                            <IconButton edge="start"
                                                onClick={() => {
                                                    setModalData(data);
                                                    setOpen(true);
                                                }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                    }
                                </Box>
                            </Box>
                            <Divider sx={{ my: 3 }} />
                        </div>
                    ))
                }
            </List>

            {/*Formulario para informes*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Informe cientifico de {modalData.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Material exclusivo para el profesional de la salud
                    </DialogContentText>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant='outlined' sx={{ mt: 2 }}>
                                <InputLabel>Especialidad</InputLabel>
                                <Select
                                    value={especialidad}
                                    label="Especialidad"
                                    onChange={onChange}
                                    name='especialidad'
                                    error={esp_err}
                                    inputRef={esp_ref}
                                >
                                    {
                                        specialtiesArray.map((res, index) =>
                                            <MenuItem key={index}
                                                value={res}>
                                                {res}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            especialidad === 'Otro'
                                ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name='especialidad2'
                                            value={especialidad2}
                                            onChange={onChange}
                                            label="Escriba la especialidad"
                                            error={esp2_err}
                                            helperText={esp2_err ? "Este campo es requerido" : ""}
                                            inputRef={esp2_ref}
                                            variant="outlined" />
                                    </FormControl>
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name='email'
                                    value={email}
                                    onChange={onChange}
                                    label="Correo electrónico"
                                    error={email_err}
                                    helperText={email_err ? "Ingrese su correo electrónico" : ""}
                                    inputRef={email_ref}
                                    InputProps={{
                                        style: { textTransform: 'lowercase' }
                                    }}
                                    variant="outlined" />
                            </FormControl>
                            {
                                !action &&
                                <MUILink
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setUser(e => ({ ...e, email: '' }));
                                        setAction(true);
                                        localStorage.removeItem('form_email_inf');
                                    }}
                                >
                                    Este no es mi correo
                                </MUILink>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox value={terms} onChange={onCheckboxChange} name='terms' />}
                                label={
                                    <Typography variant='body2'>
                                        Al confirmar manifiestas tu conformidad con
                                        nuestros <Link to="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">Términos y condiciones</Link> y
                                        nuestro <Link to="/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</Link>.
                                    </Typography>
                                }
                            />
                            <FormHelperText sx={{ color: 'red' }}>{terms_err ? 'Debe aceptar los terminos y condiciones' : ''}</FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button sx={{ textTransform: 'none' }} onClick={handleSubmit}>
                        Descargar informe
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default InformesCientificos