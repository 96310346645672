import React, { useState } from 'react';

import { ROW_EXAMPLES } from '../../../../constants/admin/blog_constants';

import {
    Button, Stack, Dialog, DialogActions, DialogContent,
    DialogTitle, Divider, Chip, ToggleButton, ToggleButtonGroup
} from '@mui/material';

import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(2)
    }
}));

const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#1976D2'
    }
});

const RowAdditionDialog = ({ open, setOpen, setRow, counter, setCounter }) => {
    const { row_counter, id_counter } = counter;
    const matches = useMediaQuery('(max-width:600px)');

    // posición de fila a insertar (top/bottom)
    const [view, setView] = useState('bottom');
    const handleChange = (event, nextView) => {
        setView(nextView);
    };

    // agregar fila de contenido
    const addRow = () => {
        if (view === 'top') // llave 'pos' no se utiliza
            setRow(e => ([
                e[0],
                { row_id: id_counter, id: `row-${row_counter}`, col: [] },
                ...e.filter((u, i) => i !== 0)
            ]));
        else
            setRow(e => ([
                ...e, { row_id: id_counter, id: `row-${row_counter}`, col: [] }
            ]));
        setCounter(e => ({
            ...e,
            row_counter: row_counter + 1,
            id_counter: id_counter + 1
        }));
        // setRowCounter(rowCounter + 1);
        setOpen(false);
    };

    return (
        <Dialog
            open={ open }
            onClose={ () => setOpen(e => !e) }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: matches ? '100%' : '30%' } }}>
            <DialogTitle id="alert-dialog-title">
                Posición de nueva fila
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={2}>
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={view}
                        exclusive
                        onChange={ handleChange }
                    >
                        <StyledToggleButton value="top" aria-label="list">
                            <VerticalAlignTopIcon />
                        </StyledToggleButton>
                        <StyledToggleButton value="bottom" aria-label="module">
                            <VerticalAlignBottomIcon />
                        </StyledToggleButton>
                    </ToggleButtonGroup>
                    <Root>
                        {
                            view === 'top' &&
                                <React.Fragment>
                                    <Divider>
                                        <Chip label="Fila al INICIO" color='primary' />
                                    </Divider>
                                    AQUÍ SE INSERTARÁ LA NUEVA FILA, EN LA PARTE SUPERIOR DEL CONTENIDO
                                </React.Fragment>
                        }
                        {
                            ROW_EXAMPLES.map((e, i) =>
                                <React.Fragment key={i}>
                                    <Divider>
                                        Ejemplo de Fila { view === 'top' ? i+2 : i+1 }
                                    </Divider>
                                    { e }
                                </React.Fragment>
                            )
                        }
                        {
                            view === 'bottom' &&
                                <React.Fragment>
                                    <Divider>
                                        <Chip label="Fila al FINAL" color='primary' />
                                    </Divider>
                                    AQUÍ SE INSERTARÁ LA NUEVA FILA, EN LA PARTE INFERIOR DEL CONTENIDO
                                </React.Fragment>
                        }
                    </Root>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => setOpen(e => !e) }>Cancelar</Button>
                <Button onClick={ addRow } autoFocus>
                    Insertar fila
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RowAdditionDialog;