import { compareAsc } from "date-fns";
import { basicTimerMsg } from "../../helpers/admin/msgHelper";

// validar cambios en valores
export const blogChangesValidator = ({ title, author, selectedCategory, imgFile, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (
        (v1_len > 3) || (v2_len > 2) ||
        selectedCategory !== '' || imgFile
    ) return true;
    else {
        for (const e of content) {
            if (e.col.length > 0)
                colCount ++;
        };
        if (colCount > 0)
            return true;
        return false;
    }
};

// validar cambios en actualización de blog antes de salir
export const blogEditionChangesValidator = (firstModel, secondModel, row, rowBackup) => {
    const { title, author, publication_date } = firstModel;
    if (title !== secondModel.title || author !== secondModel.author ||
        compareAsc(new Date(publication_date), new Date(secondModel.publication_date)) !== 0
        || row !== rowBackup)
        return true;
    return false;
};

// validación formulario
export const blogFormValidator = ({ title, author, publication_date, selectedCategory, imgFile, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (v1_len > 3 && v1_len <= 200) {
        if (v2_len > 2 && v2_len <= 120) {
            if (publication_date) {
                if (selectedCategory !== '') {
                    if (imgFile) {
                        for (const e of content) {
                            if (e.col.length > 0)
                                colCount ++;
                        };
                        if (colCount > 0)
                            return true;
                        else
                            basicTimerMsg('Ingrese contenido al blog');
                    } else
                        basicTimerMsg('Ingrese imagen de portada');
                } else
                    basicTimerMsg('Seleccione una categoría');
            } else
                basicTimerMsg('Ingrese fecha de publicación');
        } else
            basicTimerMsg('Ingrese autor de blog');
    } else
        basicTimerMsg('Ingrese título de blog');
    return false;
};

// validación formulario de edición
export const blogEditionFormValidator = ({ title, author, publication_date, selectedCategory, RowContent }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    if (v1_len > 3 && v1_len <= 200) {
        if (v2_len > 2 && v2_len <= 120) {
            if (publication_date) {
                if (selectedCategory !== '') {
                    if (RowContent.length > 0)
                        return true;
                    else
                        basicTimerMsg('Ingrese contenido al blog');
                } else
                    basicTimerMsg('Seleccione una categoría');
            } else
                basicTimerMsg('Ingrese fecha de publicación');
        } else
            basicTimerMsg('Ingrese autor de blog');
    } else
        basicTimerMsg('Ingrese título de blog');
    return false;
};