// URL mediante título de blog
export const getBlogURL = (title) => {
    return title.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\s+/g,"-").replace(/[`~#$%&@!|*/,.<>;':"/[]{}()=_+]/g, '').toLowerCase();
};

export const getFilesCount = (row, mainImg) => {
    const { imgFile, location } = mainImg;

    let files = []; // archivos a subir
    let files_count = 0; // contador de archivos
    // resumen de contenido de blog
    let text_preview = '';
    if (location === 'local') { // ¿se actualizó imagen principal?
        files = [...files, imgFile];
        files_count++;
    }
    for (const e of row) {
        for (const col of e.col) {
            if ((col.type === 'img' || col.type === 'video')
                && !col.cloud_file) { // es imagen o video recién subido del dispositivo
                files = [...files, col.file];
                files_count++;
            } else if (col.type === 'txt')
                text_preview += col.item.replace(/<h1>/g, ' ').
                    replace(/<h2>/g, ' ').
                    replace(/<h3>/g, ' ').
                    replace(/<h4>/g, ' ').
                    replace(/<h5>/g, ' ').
                    replace(/<h6>/g, ' ').
                    replace(/<br>/g, ' ').
                    replace(/<ul>/g, ' ').
                    replace(/<li>/g, ' ').
                    replace(/<ol>/g, ' ').
                    replace(/<p>/g, ' ').
                    replace(/<strong>/g, ' ').
                    replace(/<em>/g, ' ').
                    replace(/<u>/g, ' ').
                    replace(/<span>/g, ' ').
                    replace(/<\/h1>/g, ' ').
                    replace(/<\/h2>/g, ' ').
                    replace(/<\/h3>/g, ' ').
                    replace(/<\/h4>/g, ' ').
                    replace(/<\/h5>/g, ' ').
                    replace(/<\/h6>/g, ' ').
                    replace(/<\/ul>/g, ' ').
                    replace(/<\/li>/g, ' ').
                    replace(/<\/ol>/g, ' ').
                    replace(/<\/p>/g, ' ').
                    replace(/<\/strong>/g, ' ').
                    replace(/<\/em>/g, ' ').
                    replace(/<\/u>/g, ' ').
                    replace(/<\/span>/g, ' ').
                    replace(/<h1[^>]*>/g, ' ').
                    replace(/<h2[^>]*>/g, ' ').
                    replace(/<h3[^>]*>/g, ' ').
                    replace(/<h4[^>]*>/g, ' ').
                    replace(/<h5[^>]*>/g, ' ').
                    replace(/<h6[^>]*>/g, ' ').
                    replace(/<br[^>]*>/g, ' ').
                    replace(/<ul[^>]*>/g, ' ').
                    replace(/<li[^>]*>/g, ' ').
                    replace(/<ol[^>]*>/g, ' ').
                    replace(/<p[^>]*>/g, ' ').
                    replace(/<strong[^>]*>/g, ' ').
                    replace(/<em[^>]*>/g, ' ').
                    replace(/<u[^>]*>/g, ' ').
                    replace(/<span[^>]*>/g, ' ').
                    replace(/\s{2,}/g, ' ');
        };
    };
    return {
        files, files_count,
        text_preview: text_preview.substring(0, 100).trim()
    };
};

// export const getBlogTextPreview = (row) => {
//     let text_preview = '';
//     let filesCount = 0;
//     for (const e of rowContent) {
//         for (const col of e.col) {
//             if (col.type === 'img' || col.type === 'video') {
//                 data.append('file', col.file);
//                 filesCount++;
//             } else if (col.type === 'txt')
//                 text_preview += col.item.replace(/<h1>/g, ' ').
//                     replace(/<h2>/g, ' ').
//                     replace(/<h3>/g, ' ').
//                     replace(/<h4>/g, ' ').
//                     replace(/<h5>/g, ' ').
//                     replace(/<h6>/g, ' ').
//                     replace(/<br>/g, ' ').
//                     replace(/<ul>/g, ' ').
//                     replace(/<li>/g, ' ').
//                     replace(/<ol>/g, ' ').
//                     replace(/<p>/g, ' ').
//                     replace(/<strong>/g, ' ').
//                     replace(/<em>/g, ' ').
//                     replace(/<u>/g, ' ').
//                     replace(/<span>/g, ' ').
//                     replace(/<\/h1>/g, ' ').
//                     replace(/<\/h2>/g, ' ').
//                     replace(/<\/h3>/g, ' ').
//                     replace(/<\/h4>/g, ' ').
//                     replace(/<\/h5>/g, ' ').
//                     replace(/<\/h6>/g, ' ').
//                     replace(/<\/ul>/g, ' ').
//                     replace(/<\/li>/g, ' ').
//                     replace(/<\/ol>/g, ' ').
//                     replace(/<\/p>/g, ' ').
//                     replace(/<\/strong>/g, ' ').
//                     replace(/<\/em>/g, ' ').
//                     replace(/<\/u>/g, ' ').
//                     replace(/<\/span>/g, ' ').
//                     replace(/<h1[^>]*>/g, ' ').
//                     replace(/<h2[^>]*>/g, ' ').
//                     replace(/<h3[^>]*>/g, ' ').
//                     replace(/<h4[^>]*>/g, ' ').
//                     replace(/<h5[^>]*>/g, ' ').
//                     replace(/<h6[^>]*>/g, ' ').
//                     replace(/<br[^>]*>/g, ' ').
//                     replace(/<ul[^>]*>/g, ' ').
//                     replace(/<li[^>]*>/g, ' ').
//                     replace(/<ol[^>]*>/g, ' ').
//                     replace(/<p[^>]*>/g, ' ').
//                     replace(/<strong[^>]*>/g, ' ').
//                     replace(/<em[^>]*>/g, ' ').
//                     replace(/<u[^>]*>/g, ' ').
//                     replace(/<span[^>]*>/g, ' ').
//                     replace(/\s{2,}/g, ' ');
//         }
//     };
//     return text_preview.substring(0, 100).trim();
// };

export const formatPublicationDate = (d) => {
    return d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
};