import axios from 'axios';

import { htmlMsg, sweetMsg } from "../../helpers/admin/msgHelper";
import { protectedReq } from '../../helpers/request/requestHelper';

const base_url = process.env.REACT_APP_NEFRO_API_URL;

// subir imágenes de banner
export const uploadBannerImages = async(
    title, type, images, uploadedImgs, deletedImages, setDeletedImages, setData, setBanner
) => {
    const uploadedImages = uploadedImgs.map(({ banner_id }, i) => ({ banner_id, banner_pos: i }));
    const count = uploadedImages.length; // cantidad de imágenes existentes

    const data = new FormData();
    data.append('title', title);
    data.append('type', type);
    data.append('count', count);
    data.append('uploadedImages', JSON.stringify(uploadedImages));
    data.append('deletedImages', JSON.stringify(deletedImages));
    for (const item of images) {
        data.append('file', item);
    };
    const url = `${base_url}/admin-banner/upload-images`;
    // token guardado
    const token = localStorage.getItem('token') || '';
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            }
        };
        await axios.post(url, data, config).then(function(response) {
            const { data } = response;
            if (data.status) {
                const { resp: {
                    banners_id, // lista de banners subidos
                    all_good // banners removidos han sido eliminados de BD/Google Drive
                } } = data;
                const txt = all_good ? `<p>Banners actualizados</p>`
                : `<p>Banners actualizados</p><h5>No se han eliminado las imágenes de uno o mas banners</h5>`;
                htmlMsg('success', 'Éxito', txt);

                // agregar banners a lista
                setData(e => ({
                    ...e,
                    banners: {
                        ...e.banners,
                        index: {
                            ...e.banners.index,
                            [type]: [
                                ...e.banners.index[type],
                                ...banners_id
                            ]
                        }
                    },
                    banners_backup: {
                        ...e.banners,
                        index: {
                            ...e.banners.index,
                            [type]: [
                                ...e.banners.index[type],
                                ...banners_id
                            ]
                        }
                    }
                }));
                // imágenes fueron eliminadas
                setDeletedImages(e => ({
                    ...e, [type]: []
                }));
                // remover imágenes subidas de dispositivo
                setBanner(e => ({ ...e, images: [] }));
            } else {
                const { msg } = data;
                if (msg === 'no-content') {
                    const txt = images.length > 1 ? ' los banners' : 'l banner';
                    sweetMsg('info', 'Banner',
                    `No se ha enviado información de${txt}`);
                } else if (msg === 'missing-info') {
                    const txt = images.length > 1 ? ' los banners' : 'l banner';
                    sweetMsg('error', 'Banner',
                    `No se ha enviado información completa de${txt}`);
                } else if (msg === 'axios-err') {
                    const txt = images.length > 1 ? 'el banner' : 'los banners';
                    sweetMsg('error', 'Banner',
                    `Hubo un problema al guardar ${txt}`);
                } else if (msg === 'server-err') {
                    const txt = images.length > 1 ? 'el banner' : 'los banners';
                    sweetMsg('error', 'Banner',
                    `Incidente al guardar ${txt}`);
                } else
                    sweetMsg('warning', 'Banner',
                    'Inténtelo de nuevo, por favor');
            }
        })
        .catch(function(ax_err) {
            sweetMsg('error', 'Banner',
            'Las imágenes no se han subido');
        });
    } catch (err) {
        sweetMsg('error', 'Banner',
        'No se subieron las imágenes');
    };
};

// actualizar banners en BD
export const updateBanners = async (type, updImages, deletedImages, setDeletedImages, setData, setBanner) => {
    try {
        const uploadedImages = updImages.map(({ banner_id }, i) => ({ banner_id, banner_pos: i }));
        const resp = await protectedReq('admin-banner/update', {
            uploadedImages, deletedImages
        }, 'PUT');
        const body = await resp.json();
        if (body.status) {
            // banners removidos han sido eliminados de BD/Google Drive
            const { all_good } = body.resp;

            const txt = all_good ? `<p>Banners actualizados</p>`
            : `<p>Banners actualizados</p><h5>No se han eliminado las imágenes de uno o mas banners</h5>`;
            htmlMsg('success', 'Éxito', txt);

            setData(e => ({
                ...e, banners: {
                    ...e.banners,
                    index: {
                        ...e.banners.index,
                        [type]: [
                            ...e.banners.index[type].filter(u => !deletedImages.includes(u.banner_id))
                        ]
                    }
                },
                banners_backup: {
                    ...e.banners,
                    index: {
                        ...e.banners.index,
                        [type]: [
                            ...e.banners.index[type].filter(u => !deletedImages.includes(u.banner_id))
                        ]
                    }
                }
            }));
            // imágenes fueron eliminadas
            setDeletedImages(e => ({
                ...e, [type]: []
            }));
            // remover imágenes subidas de dispositivo
            setBanner(e => ({ ...e, images: [] }));
        } else {
            const { msg } = body;
            if (msg === 'no-content')
                sweetMsg('info', 'Banner',
                `No se ha enviado información de los banners`);
            else if (msg === 'axios-err')
                sweetMsg('error', 'Banner',
                `Hubo un problema al actualizar los banners`);
             else if (msg === 'server-err')
                sweetMsg('error', 'Banner',
                `Incidente al actualizar los banners`);
            else
                sweetMsg('warning', 'Banner',
                'Inténtelo de nuevo, por favor');
        }
    } catch (err) {
        sweetMsg('error', 'Admin', 'Recargue la página');
        return false;
    };
};