import { createContext, useState, useEffect, useContext } from "react";

import { UIContext } from "./UIContext";
import { RouterContext } from "./RouterContext";

import { userSession } from "../requests/user";

export const AdminContext = createContext();
const { Provider } = AdminContext;

const AdminContextProvider = ({ children }) => {
    // contexto padre
    const { stopLoading } = useContext(UIContext);
    const { userLogin } = useContext(RouterContext);

    const [data, setData] = useState({
        // blogPreview: { // vista previa de blog (antes de guardar)
        //     blog_id: '0',
        //     title: '',
        //     url: '',
        //     preview: '',
        //     publication_date: new Date(),
        //     author: '',
        //     category_id: '0',
        //     imgId: '',
        //     content: []
        // },
        blogState: { // blog en edición
            blog_id: 0,
            title: '',
            url: '',
            preview: '',
            publication_date: new Date(),
            author: '',
            category_id: 0,
            imgId: '',
            content: []
        },
        blockState: { // bloque de contenido en edición (QuillEditor)
            item: '', // bloque (html)
            src: '', // recurso (sólo para imagen/video)
            file: '', // archivo
            type: 'txt', // tipo de bloque (texto/imagen/video)
            xs: 12, // vista de bloque para móviles
            md: 12, // vista de bloque para es
            id: 'col-0', // ID para Drag&Drop
            cloud_file: false // archivo subido a CDN
        },
        blog: [],
        categories: [], // categorías de blog
        banners: { // banners de página principal
            index: {
                desktop: [],
                tablet: [],
                mobile: []
            }
        },
        banners_backup: { // estado inicial de banners
            index: {
                desktop: [],
                tablet: [],
                mobile: []
            }
        },
        adminUsers: [], // usuarios administrador
        initialState: true // estado inicial de contexto (antes de petición)
    });
    const { initialState } = data;
    
    useEffect(() => {
        // console.log(data);
        // renovamos la sesión del usuario cuando se hacen cambios en sitio
        // mantiene la sesión viva (se reinicia timeout de 2hrs)
        if (!initialState)
            userSession(userLogin, stopLoading);
    }, [data]);
    
    return (
        <Provider value={{ data, setData }}>
            { children }
        </Provider>
    );
};

export default AdminContextProvider;