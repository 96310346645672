import localImg from '../../Assets/Resources/blog/no-image-icon.png';
import localVideo from '../../Assets/Resources/blog/video-icon.png';

const colors = ["red", "green", "blue", "orange", "violet", "brown"];
export const QUILL_FORMATS = [
    [
        {
            className: "ql-font",
            options: ['serif', 'monospace']
        },
        {
            className: "ql-size",
            options: ["small", "large", "huge"]
        }
    ],
    [
        { className: "ql-bold" }, { className: "ql-italic" }, { className: "ql-underline" }, { className: "ql-strike" }
    ],
    [
        {
            className: "ql-color",
            options: colors
        },
        {
            className: "ql-background",
            options: colors
        }
    ],
    [
        {
            className: "ql-script",
            value: "sub"
        },
        {
            className: "ql-script",
            value: "super"
        }
    ],
    [
        {
            className: "ql-header",
            value: "1"
        },
        {
            className: "ql-header",
            value: "2"
        },
        {
            className: "ql-blockquote"
        },
        {
            className: "ql-code-block"
        }
    ],
    [
        {
            className: "ql-list",
            value: "ordered"
        },
        {
            className: "ql-list",
            value: "bullet"
        },
        {
            className: "ql-indent",
            value: "-1"
        },
        {
            className: "ql-indent",
            value: "+1"
        }
    ],
    [
        {
            className: 'ql-direction',
            value: 'rtl'
        },
        {
            className: 'ql-align',
            options: ['right', 'center', 'justify']
        }
    ],
    [
        { className: 'ql-link' }, { className: 'ql-image' }, { className: 'ql-video' }, { className: 'ql-formula' }
    ]
];

export const FORMATS = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
];

// ReactQuill constants
export const MODULES = {
    toolbar: {
        container: "#toolbar",
    }
};
export const MODULES_QUILL = {
    toolbar: {
        container: "#mytoolbar",
    }
};

// valores iniciales blog
export const BLOG_INIT = {
    title: '',
    author: '',
    publication_date: new Date()
};

// portada por defecto de blog
export const BLOG_MAIN_IMG = {
    imgSrc: localImg,
    imgFile: ''
};
// portada por defecto de blog (actualización)
export const BLOG_UPD_IMG = {
    imgSrc: localImg, // mostrar en source de <img>
    imgFile: '', // File
    location: 'google_drive' // nueva imagen (local) o en nube (Google Drive)
};

export const BLOG_ROW_INIT = [
    {
        col: [],
        id: 'row-0'
    },
    {
        col: [],
        id: 'row-1'
    }
];

// longitud de columna para bloque
export const BLOCK_LENGTH = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
];

// portada de video en contenido de blog
export const BLOG_VIDEO = localVideo;
export const BLOG_COVER = '1hSrUHFV-PUYj4WMVYc8KFb-jffB_UpNL';
export const NIN_COVER = '1ZsiWQE86kemQpz-V2solLDLLEwa4HP3Y';

export const BLOG_VIEW = {
    blog_id: '',
    title: '',
    url: '',
    author: '',
    publication_date: '',
    category: [],
    imgId: BLOG_COVER,
    content: [],
    preview: ''
};

export const ROW_EXAMPLES = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam'
];