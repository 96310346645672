import React from 'react';

import {
    Dialog, DialogTitle
} from '@mui/material';
import BannerContainer from './ImagesContainer/BannerContainer';

const BannersDialog = ({
    handleCloseDialog, openDialog,
    banner, setBanner,
    deletedImages, setDeletedImages,
    loading, setLoading
}) => {
    return (
        <Dialog onClose={ handleCloseDialog }
            open={ openDialog }
            fullWidth={ true }
            maxWidth='xl'
            sx={{ zIndex: 500 }}>
            <DialogTitle>Editar diseño</DialogTitle>
            <BannerContainer
                banner={ banner }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
                loading={ loading }
                setLoading={ setLoading }
            />
        </Dialog>
    );
};

export default BannersDialog;