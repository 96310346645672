import React, { useState, useContext, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { AdminContext } from '../../../../context/AdminContext';

import BannerImageList from './BannerImageList';

import { basicMsg } from '../../../../helpers/admin/msgHelper';
import { updateBanners, uploadBannerImages } from '../../../../requests/admin/banner';

import {
    Button, Card, CardActions, CardMedia,
    Container, DialogActions, IconButton,
    ImageList, ImageListItem, useMediaQuery
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from '@mui/material/styles';

import { regex_file_ext } from '../../../../constants/regex';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const BannerContainer = ({
    banner, setBanner, deletedImages, setDeletedImages, loading, setLoading
}) => {
    const { type, images } = banner;
    
    const matches = useMediaQuery('(max-width: 768.5px)');

    const { data: { banners: { index } }, setData } = useContext(AdminContext);

    const fileInputRef = useRef();
    
    // const [loading, setLoading] = useState(false);
    // se utiliza para modificar nombre de archivos/imágenes repetidas
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        fileInputRef.current.value = "";
    }, [images]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setBanner(e => ({
            ...e,
            images: items
        }));
    };
    
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let duplicate_img = false;
        let exceeded = false; // ¿se alcanzó el límite?

        if (count >= 20) {
            basicMsg('No puede agregar más imágenes');
            return;
        }
        if (target.files.length > 0) {
            let target_files = [];
            for (const element of [...target.files]) {
                if (count >= 20) exceeded = true;
                else {
                    const { name, type } = element;
                    // archivo es una imagen compatible
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(type)) {
                        const file_ext = regex_file_ext.exec(name)[1]; 
                        if (file_ext) { // extensión es válida
                            // mismo archivo ya se ha subido => editar nombre
                            if (images.find(e => e.name === name)) {
                                // nuevo nombre de archivo
                                // nombre anterior sin extensión + contador + . + extensión
                                const file_name = `${name.replace(/\.[^/.]+$/, "")}_${counter}.${file_ext}`;
                                target_files = [
                                    ...target_files, new File([element], file_name, { type })
                                ];
                                duplicate_img = true;
                            } else
                                target_files = [...target_files, element];
                            count++;
                        }
                    }
                }
            };
            if (duplicate_img) // hubo por lo menos una imagen duplicada
                setCounter(counter + 1);
            setBanner(e => ({
                ...e,
                images: [...e.images, ...target_files]
            }));
            if (exceeded) basicMsg('Se alcanzó el límite de imágenes (20)');
        }
    };
    
    // remueve imágenes recién subidas del dispositivo
    const removeImg = name => () => {
        setBanner(e => ({
            ...e,
            images: e.images.filter(i => (i.name !== name))
        }));
    };

    // guardar imágenes de banners en Google Drive
    const save = async() => {
        setLoading(true);
        
        if (images.length <= 0)
            await updateBanners(
                type, index[type],
                deletedImages[type], setDeletedImages,
                setData, setBanner
            );
        else {
            await uploadBannerImages(
                'index', type, images,
                index[type],
                deletedImages[type], setDeletedImages,
                setData, setBanner
            );
        }
        setLoading(false);
    };

    return (
        <Container maxWidth='xl'>
            
            <BannerImageList
                type={ type }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />

            <DragDropContext onDragEnd={ handleOnDragEnd }>
                <Droppable droppableId="file">
                    {(provided) => (
                        <ImageList gap={20} cols={ matches ? 1 : 3 }
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }>
                            {
                                images.length > 0 &&
                                images.map((img, i) => {
                                    return (
                                        <Draggable key={ img.name }
                                            draggableId={ img.name }
                                            index={i}>
                                            {(provided) => (
                                                <Card key={i}
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }>
                                                    <CardMedia
                                                        component="img"
                                                        alt={ 'banner-nin-institute' }
                                                        height="140"
                                                        image={ URL.createObjectURL(img) }
                                                    />
                                                    <CardActions>
                                                        <IconButton
                                                            onClick={ removeImg(img.name) }
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            )}
                                        </Draggable>
                                    );
                                })
                            }
                            { provided.placeholder }
                            <ImageListItem className='uploader'>
                                <Button
                                    component="label"
                                    role={ undefined }
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CameraAltIcon />}
                                >
                                    Subir imágenes
                                    <VisuallyHiddenInput
                                        ref={ fileInputRef }
                                        accept="image/*"
                                        type="file"
                                        multiple
                                        name="images"
                                        onChange={ loadedImages }
                                    />
                                </Button>
                            </ImageListItem>
                        </ImageList>
                    )}
                </Droppable>
            </DragDropContext>

            <DialogActions>
                {
                    loading ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                        >
                            Guardando
                        </LoadingButton>
                    :
                        <Button onClick={ save }>Guardar cambios</Button>
                }
            </DialogActions>
        </Container>
    );
};

export default BannerContainer;