import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import '../blog.css';

import { AdminContext } from '../../../../context/AdminContext';

import { getBlogURL } from '../../../../helpers/admin/adminBlogHelper';
import { saveBlogDB, uploadBlogContentImages } from '../../../../requests/admin/blog';
import { sweetMsg, basicMsg, simpleConfirmDialog } from '../../../../helpers/admin/msgHelper';
import { blogChangesValidator, blogFormValidator } from '../../../../validators/admin/AdminBlogValidator';

import BlogCategory from '../BlogCategory';
import CustomToolbar from '../CustomToolbar';
import DragDropRow from './child/DragDropRow';
import BlockDialog from './child/BlockDialog';
import CategoryDialog from '../child/CategoryDialog';
import QuillEditDialog from './child/QuillEditDialog';
import ImageBlockDialog from './child/ImageBlockDialog';
import BasicDateTimePicker from './child/BlogDatePicker';
import RowAdditionDialog from '../child/RowAdditionDialog';

import {
    BLOG_INIT, BLOG_MAIN_IMG, BLOG_ROW_INIT,
    BLOG_VIDEO, BLOG_COVER, FORMATS, MODULES
} from '../../../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../../../constants/main_constants';

//Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//MUI
import {
    Box, Container, TextField, Typography,
    Button, Stack, Fab, Grid, Backdrop,
    CircularProgress, IconButton
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const BlogPost = () => {
    const navigate = useNavigate();
    const { setData } = useContext(AdminContext);
    const [loading, setLoading] = useState(false);
    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState('');

    // texto de Quill
    const [text, setText] = useState('');
    // contenido en filas/columnas
    const [row, setRow] = useState(BLOG_ROW_INIT);
    // mostrar diálogo de edición de bloque
    const [open, setOpen] = useState(false);
    // mostrar diálogo de longitud de bloque
    const [open1, setOpen1] = useState(false);

    // archivos para contenido de blog
    const [tmpFiles, setTmpFiles] = useState([]);
    // mostrar diálogo de longitud de bloque de imagen
    const [open2, setOpen2] = useState(false);

    // diálogo para agregar fila
    const [open3, setOpen3] = useState(false);

    // diálogo para editar categorías
    const [open4, setOpen4] = useState(false);
    
    // contador de filas y columnas (para asignar ID's)
    const [counter, setCounter] = useState({
        row_counter: 2,
        col_counter: 0,
        id_counter: 0
    });
    const { col_counter } = counter;

    // longitud de columnas según el dispositivo
    const [xs, setXS] = useState(12);
    const [md, setMD] = useState(6);

    const [mainImg, setMainImg] = useState(BLOG_MAIN_IMG);
    const { imgSrc, imgFile } = mainImg;

    // información de blog
    const [values, setValues] = useState(BLOG_INIT);
    const { title, author, publication_date } = values;

    // cambios en Quill Editor
    const handleChange = (html) => {
        if (html === '<p><br></p>')
            setText('');
        else
            setText(html);
    };

    // cambios en campos
    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'title') {
            if (value.length <= 200)
                setValues({ ...values, [name]: value });
        } else if (name === 'author') {
            if (value.length <= 120)
                setValues({ ...values, [name]: value });
        }
    };
    
    // guardar bloque
    const addBlock = () => {
        if (text !== '') {
            setOpen1(true);
        } else basicMsg('Ingrese contenido en el recuadro');
    };

    // seleccionar longitud de bloque y guardar
    const insertBlock = () => {
        let content = [...row];
        content[0].col.push({
            item: text,
            src: '', file: '',
            type: 'txt', xs, md,
            id: `col-${col_counter}`,
            col_id: 0 // insertar en BD, no actualizar
        });
        setRow(content);
        setCounter(e => ({
            ...e,
            col_counter: col_counter + 1
        }));
        // setColCounter(colCounter + 1);
        setOpen1(false);
        // limpiar campo de Quill
        setText('');
    };

    // limpiar contenido de Quill
    const cleanBlock = () => {
        if (text !== '')
            Swal.fire({
                title: 'Limpiar el contenido del bloque',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed)
                    setText('');
            });
    };

    // subir imagen/video para contenido de blog
    const uploadFiles = async({ target }) => {
        if (target.files.length > 0) {
            setOpen2(true);
            setTmpFiles([...target.files]);
        }
    };

    const insertFileBlock = async () => {
        const promise = await loadFiles();
        if (promise) {
            const { columns, counter } = promise;
            let content = [...row];
            content[0].col.push(...columns);
            setRow(content);
            setCounter(e => ({
                ...e,
                col_counter: counter
            }));
            // setColCounter(counter);
            setOpen2(false);
        }
    };

    // cargar archivos mediante Promesa
    const loadFiles = async() => {
        if (tmpFiles.length > 0) {
            let counter = col_counter;
            const filePromise = tmpFiles.map(file => {
                return new Promise((resolve, reject) => {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (event) => {
                            const src = event.target.result;
                            const item = `<img src="${src}" alt="imagen-blog-NIN-Institute">`;
                            resolve({
                                item, src,
                                file, type: 'img',
                                xs, md,
                                id: `col-${counter}`
                            });
                            counter++;
                        };
                    } else if (file.type === 'video/mp4') {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = (event) => {
                            const src = event.target.result;
                            const item = `<div><h6>Video: ${file.name}</h6><img style="height: 200px; display:block; margin: auto;" src=${BLOG_VIDEO} alt="video-blog-NIN-Institute"></div>`;
                            resolve({
                                item, src,
                                file, type: 'video',
                                xs, md,
                                id: `col-${counter}`
                            });
                            counter++;
                        };
                    }
                });
            });
            return {
                columns: await Promise.all(filePromise),
                counter
            };
        }
    };

    // subir portada de Blog
    const uploadMainImg = ({ target }) => {
        const file = target.files[0]
        if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (event) => {
                const src = event.target.result;
                setMainImg({ ...mainImg,
                    imgSrc: src,
                    imgFile: file
                });
            };
        }
    };
    
    // guardar blog en BD
    const saveBlog = () => {
        if (blogFormValidator({ ...values, selectedCategory, imgFile, row })) {
            Swal.fire({
                title: '¿Guardar el blog?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then(async(result) => {
                if (result.isConfirmed) {
                    setLoading(true);

                    let text_content = '';

                    const data = new FormData();
                    let filesCount = 1; // 1 por imagen de portada
                    data.append('title', title);
                    data.append('file', imgFile);
                    // quitamos la fila de 'bloques añadidos'
                    const rowContent = row.filter((e, i) => i !== 0 && e.col.length > 0);
                    for (const e of rowContent) {
                        for (const col of e.col) {
                            if (col.type === 'img' || col.type === 'video') {
                                data.append('file', col.file);
                                filesCount++;
                            } else if (col.type === 'txt')
                                text_content += col.item.replace(/<h1>/g, ' ').
                                    replace(/<h2>/g, ' ').
                                    replace(/<h3>/g, ' ').
                                    replace(/<h4>/g, ' ').
                                    replace(/<h5>/g, ' ').
                                    replace(/<h6>/g, ' ').
                                    replace(/<br>/g, ' ').
                                    replace(/<ul>/g, ' ').
                                    replace(/<li>/g, ' ').
                                    replace(/<ol>/g, ' ').
                                    replace(/<p>/g, ' ').
                                    replace(/<strong>/g, ' ').
                                    replace(/<em>/g, ' ').
                                    replace(/<u>/g, ' ').
                                    replace(/<span>/g, ' ').
                                    replace(/<\/h1>/g, ' ').
                                    replace(/<\/h2>/g, ' ').
                                    replace(/<\/h3>/g, ' ').
                                    replace(/<\/h4>/g, ' ').
                                    replace(/<\/h5>/g, ' ').
                                    replace(/<\/h6>/g, ' ').
                                    replace(/<\/ul>/g, ' ').
                                    replace(/<\/li>/g, ' ').
                                    replace(/<\/ol>/g, ' ').
                                    replace(/<\/p>/g, ' ').
                                    replace(/<\/strong>/g, ' ').
                                    replace(/<\/em>/g, ' ').
                                    replace(/<\/u>/g, ' ').
                                    replace(/<\/span>/g, ' ').
                                    replace(/<h1[^>]*>/g, ' ').
                                    replace(/<h2[^>]*>/g, ' ').
                                    replace(/<h3[^>]*>/g, ' ').
                                    replace(/<h4[^>]*>/g, ' ').
                                    replace(/<h5[^>]*>/g, ' ').
                                    replace(/<h6[^>]*>/g, ' ').
                                    replace(/<br[^>]*>/g, ' ').
                                    replace(/<ul[^>]*>/g, ' ').
                                    replace(/<li[^>]*>/g, ' ').
                                    replace(/<ol[^>]*>/g, ' ').
                                    replace(/<p[^>]*>/g, ' ').
                                    replace(/<strong[^>]*>/g, ' ').
                                    replace(/<em[^>]*>/g, ' ').
                                    replace(/<u[^>]*>/g, ' ').
                                    replace(/<span[^>]*>/g, ' ').
                                    replace(/\s{2,}/g, ' ');
                        }
                    };
                    // previsualización (texto)
                    const preview = text_content.substring(0, 100).trim();
                    
                    // subida de imágenes a Drive
                    const req = await uploadBlogContentImages(data, 'upload', setLoading);
                    if (req.status) {
                        const { folderId, imagesId } = req.resp;

                        const url = getBlogURL(title.trim());
                        
                        let i = 0; // contador de filas
                        let content = []; // contenido dinámico hacia BD

                        if (filesCount === imagesId.length) {
                            let j = 0; // contador de archivos
                            // arreglo de ID's de archivos
                            const images = imagesId.length > 1 ? imagesId.filter((e, i) => i !== 0) : [];
                            for (const e of rowContent) {
                                content = [...content, {
                                    col: []
                                }];
                                for (const col of e.col) {
                                    const { item, type, xs, md } = col;
                                    if (col.type === 'img') {
                                        content[i].col = [...content[i].col, {
                                            item: `<img src="${GOOGLE_DRIVE_CDN}${images[j]}" alt="imagen-blog-NIN-Institute">`,
                                            type, xs, md,
                                            fileId: images[j]
                                        }];
                                        j++;
                                    } else if (col.type === 'video') {
                                        content[i].col = [...content[i].col, {
                                            item: `<iframe src="https://drive.google.com/file/d/${images[j]}/preview" title="video-blog-NIN-Institute" width="100%" height="100%"></iframe>`,
                                            type, xs, md,
                                            fileId: images[j]
                                        }];
                                        j++;
                                    } else {
                                        content[i].col = [...content[i].col, {
                                            item, type, xs, md,
                                            fileId: ''
                                        }];
                                    }
                                }
                                i++;
                            }
                            if (await saveBlogDB({
                                ...values,
                                url, preview,
                                category_id: selectedCategory,
                                content,
                                folderId,
                                imgId: imagesId[0]
                            }, setData))
                                returnToBlog();
                        } else {
                            sweetMsg('warning', 'Blog',
                            'No se han subido todas las imágenes');
                            for (const e of rowContent) {
                                content = [...content, {
                                    col: []
                                }];
                                for (const col of e.col) {
                                    const { item, type, xs, md } = col;
                                    if (col.type !== 'img' && col.type !== 'video')
                                        content[i].col = [...content[i].col, {
                                            item, type, xs, md,
                                            fileId: ''
                                        }];
                                }
                                i++;
                            }
                            if (await saveBlogDB({
                                ...values,
                                url, preview,
                                category_id: selectedCategory,
                                content,
                                folderId,
                                imgId: BLOG_COVER
                            }, setData))
                                returnToBlog();
                        }
                    }
                    setLoading(false);
                }
            });
        }
    };

    const returnToBlog = () => {
        setRow(BLOG_ROW_INIT);
        navigate('/nin-admin/blog');
    };

    // volver a lista de blog
    const goBack = async () => {
        if (blogChangesValidator({ ...values, selectedCategory, imgFile, row })) {
            if (await simpleConfirmDialog('¿Salir sin guardar los cambios?', 'Sí, salir'))
                navigate('/nin-admin/blog');
        } else navigate('/nin-admin/blog');
    };

    const seeBlogPreview = () => {
        localStorage.setItem('blog_preview', JSON.stringify({
            blog_id: '0', title, publication_date, content: row
        }));
        window.open('/nin-admin/vista-previa-blog', '_blank', 'rel=noopener noreferrer');
    };

    return (
        <Container maxWidth="xl" sx={{ mb: 5, mt: '90px' }}>
            <Stack direction="row"
                alignItems="flex-start"
                spacing={2} sx={{ pb: 2 }}>
                <IconButton aria-label="go-back"
                    sx={{
                        backgroundColor: '#1976D2', color: 'white', '&:hover': {
                            backgroundColor: 'black'
                        }
                    }}
                    onClick={ goBack }>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h5' paragraph>
                    Agregar nuevo blog
                </Typography>
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth
                        sx={{ mb: 2 }}
                        variant='outlined'
                        label="Título del blog"
                        size='small'
                        name='title'
                        inputProps={{ maxLength: 200 }}
                        value={ title }
                        onChange={ handleOnChange }
                    />
                    <TextField fullWidth
                        sx={{ mb: 2 }}
                        variant='outlined'
                        label="Autor del blog"
                        size='small'
                        name='author'
                        inputProps={{ maxLength: 120 }}
                        value={ author }
                        onChange={ handleOnChange }
                    />
                    <BasicDateTimePicker
                        fullWidth
                        values={ values }
                        setValues={ setValues }
                    />
                    <BlogCategory
                        selectedCategory={ selectedCategory }
                        setSelectedCategory={ setSelectedCategory }
                        setOpen={ setOpen4 }
                    />
                </Grid>
                
                <Grid item xs={12} md={3}>
                    <Stack direction="column">
                        <Button variant="outlined" startIcon={<InsertPhotoIcon />}
                            component="label">
                            <input hidden accept="image/*"
                                type="file" onChange={ uploadMainImg }
                            />
                            Imagen de portada
                        </Button>
                        <img src={ imgSrc } alt='portada-blog'
                            className='img-fluid'/>
                    </Stack>
                </Grid>
            </Grid>
            
            <Box sx={{ mt: 3 }}>
                <CustomToolbar />
                <ReactQuill
                    value={ text }
                    name='quill'
                    onChange={ handleChange }
                    modules={ MODULES }
                    formats={ FORMATS }
                />
            </Box>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="contained"
                    onClick={ addBlock }>
                    Guardar
                </Button>
                <Button onClick={ cleanBlock }>
                    Limpiar
                </Button>
                <Button variant="outlined" startIcon={<InsertPhotoIcon />} component="label">
                    <input hidden accept="image/*, video/*"
                        type="file" onChange={ uploadFiles }
                        multiple="multiple" />
                    Agregar imagen/video
                </Button>
            </Stack>

            <Box sx={{ mt: 3 }}>
                <Typography variant='body1'>Vista previa bloque (HTML)</Typography>
                <textarea defaultValue={text} rows={5} style={{ width: '100%' }} disabled></textarea>
            </Box>
            
            <DragDropRow setOpen={ setOpen }
                row={ row }
                setRow={ setRow }
            />

            <Button variant='contained'
                onClick={ () => setOpen3(true) }>
                Agregar fila
            </Button>

            <QuillEditDialog open={ open }
                setOpen={ setOpen }
                row={ row } setRow={ setRow }
            />
            <BlockDialog open={ open1 }
                setOpen={ setOpen1 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertBlock={ insertBlock }
            />
            <ImageBlockDialog open={ open2 }
                setOpen={ setOpen2 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertFileBlock={ insertFileBlock }
            />
            <RowAdditionDialog open={ open3 }
                setOpen={ setOpen3 }
                setRow={ setRow }
                counter={ counter }
                setCounter={ setCounter }
            />
            <CategoryDialog open={ open4 }
                setOpen={ setOpen4 }
                selectedCategory={ selectedCategory }
                setSelectedCategory={ setSelectedCategory }
            />

            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    left: (theme) => theme.spacing(2)
                }}
                disabled={ loading }
                onClick={ saveBlog }>
                <SaveIcon />
            </Fab>

            <Fab color="secondary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                disabled={ loading }
                onClick={ seeBlogPreview }>
                <VisibilityIcon />
            </Fab>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ loading }>
                <CircularProgress />
            </Backdrop>
        </Container>
    );
};

export default BlogPost;