import axios from 'axios';

import { convertToLocalTime } from 'date-fns-timezone';

import { sweetMsg } from "../../helpers/admin/msgHelper";
import { protectedReq } from "../../helpers/request/requestHelper";
import { formatPublicationDate } from '../../helpers/admin/adminBlogHelper';

const base_url = process.env.REACT_APP_NEFRO_API_URL;

// registrar nueva categoría de blog
export const addBlogCategory = async (category, setData, setSelectedCategory) => {
    try {
        const resp = await protectedReq('admin-blog/add-category', { category }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { resp: { category_id } } = body;
            setData(e => ({
                ...e, categories: [...e.categories, {
                    category_id,
                    category, checked: false
                }]
            }));
            setSelectedCategory(category_id);
            return true;
        } else {
            const { msg } = body;
            if (msg === 'miss')
                sweetMsg('info', 'Blog',
                'No se ha enviado la categoría');
            else if (msg === 'category-exists')
                sweetMsg('info', 'Blog',
                'La categoría ya existe');
            else if (msg === 'sql-err')
                sweetMsg('error', 'Blog',
                'Problema en conexión con BD');
            else if (msg === 'server-err')
                sweetMsg('error', 'Blog',
                'El servidor ha dejado de responder');
            else
                sweetMsg('warning', 'Blog',
                'Categoría no registrada');
            return false;
        }
    } catch (err) {
        sweetMsg('error', 'Blog',
        'No se puede registrar nueva categoría');
        return false;
    };
};

// eliminar categoría de blog
export const deleteBlogCategory = async (
    { category_id, category }, selectedCategory, setSelectedCategory, setData
) => {
    try {
        const resp = await protectedReq('admin-blog/delete-category', { category_id }, 'DELETE');
        const body = await resp.json();
        if (body.status) {
            setData(e => ({
                ...e,
                categories: [
                    ...e.categories.filter(u => u.category_id !== category_id)
                ]
            }));
            if (selectedCategory === category)
                setSelectedCategory('');
            return true;
        } else {
            const { msg } = body;
            if (['miss', 'missing-category'].includes(msg))
                sweetMsg('info', 'Blog',
                'No se ha enviado la categoría');
            else if (msg === 'category-not-deleted')
                sweetMsg('info', 'Blog',
                'No se ha eliminado la categoría');
            else if (msg === 'no-content')
                sweetMsg('info', 'Blog',
                'Falta información');
            else if (msg === 'php-err')
                sweetMsg('info', 'Blog',
                `No se ha eliminado la categoría. La razón podría ser que tiene un blog con la categoría '${category}'`);
            else if (['server-err', 'ax-err'].includes(msg))
                sweetMsg('error', 'Blog',
                'El servidor ha dejado de responder');
            else
                sweetMsg('warning', 'Blog',
                'Categoría no eliminada');
            return false;
        }
    } catch (err) {
        sweetMsg('error', 'Blog',
        'No se puede eliminar la categoría');
        return false;
    };
};

// subir imágenes de contenido y principal
export const uploadBlogContentImages = async(data, endpoint, setLoading) => {
    const url = `${base_url}/admin-blog/content-images-${endpoint}`;
    // token guardado
    const token = localStorage.getItem('token') || '';
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            }
        };
        return await axios.post(url, data, config).then(function(response) {
            const { data: { resp } } = response;
            return { status: true, resp };
        })
        .catch(function(err) {
            setLoading(false);
            sweetMsg('error', 'Blog',
            'Los archivos no se han subido');
            return { status: false };
        });
    } catch (err) {
        setLoading(false);
        sweetMsg('error', 'Blog',
        'No se subieron los archivos');
        return { status: false };
    };
};

// guardar entrada de blog
export const saveBlogDB = async (model, setData) => {
    try {
        const { publication_date, content, ...blog } = model;
        const timeZone = "America/Mexico_City";
        const output = convertToLocalTime(publication_date, { timeZone });
        const resp = await protectedReq('admin-blog/save', {
            ...blog, publication_date: output, content
        }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { blog_id, category, row_ids, col_ids } = body.resp;
            let j=-1;
            setData(e => ({
                ...e, blog:
                [
                    ...e.blog,
                    { ...model,
                        blog_id, category,
                        // publication_date: publication_date.toLocaleString(),
                        publication_date: formatPublicationDate(publication_date),
                        content: content.map(
                            (e, i) => ({
                                ...e, id: row_ids[i],
                                col: [
                                    ...e.col.map(u => {
                                        j++;
                                        return {
                                            ...u, id: col_ids[j]
                                        }
                                    })
                                ]
                            })
                        )
                    }
                ]
            }));
            sweetMsg('success', 'Éxito',
            'Blog registrado');
            return true;
        } else {
            if (body.msg === 'not-saved')
                sweetMsg('warning', 'Blog',
                'Blog no fue guardado');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Blog',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Blog',
                'Inténtelo de nuevo, por favor');
        }
        return false;
    } catch (err) {
        sweetMsg('error', 'Blog',
        'No se pudo guardar el blog');
        return false;
    };
};

// actualizar blog
export const updateBlogDB = async (model, setLoading, setData) => {
    try {
        const { blog_id, content } = model;
        const resp = await protectedReq('admin-blog/update', model, 'PUT');
        const body = await resp.json();
        if (body.status) {
            const { row_ids, col_ids } = body.resp;
            let j=-1;

            setData(e => ({
                ...e, blog: [
                    ...e.blog.map(
                        u => u.blog_id === blog_id ?
                            ({
                                ...u, ...model,
                                content: content.map(
                                    (e, i) => ({
                                        ...e, id: row_ids[i],
                                        col: [
                                            ...e.col.map(u => {
                                                j++;
                                                return {
                                                    ...u, id: col_ids[j]
                                                }
                                            })
                                        ]
                                    })
                                )
                            })
                        : u
                    )
                ]
            }));
            sweetMsg('success', 'Éxito',
            'Blog actualizado');
        } else {
            if (body.msg === 'miss')
                sweetMsg('info', 'Blog',
                'Falta información de blog');
            else if (body.msg === 'blog-not-updated')
                sweetMsg('info', 'Blog',
                'Blog no fue actualizado');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Blog',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Blog',
                'Inténtelo de nuevo, por favor');
        }
        setLoading(false);
        return body.status;
    } catch (err) {
        setLoading(false);
        sweetMsg('error', 'Blog',
        'No se pudo actualizar el blog');
        return false;
    };
};

// eliminar blog
export const deleteBlogDB = async (item, setData) => {
    try {
        const { blog_id, folderId } = item;
        const resp = await protectedReq('admin-blog/delete', { blog_id, folderId }, 'DELETE');
        const body = await resp.json();
        if (body.status)
            setData(e => ({
                ...e,
                blog: e.blog.filter(u => u.blog_id !== blog_id)
            }));
        else {
            if (body.msg === 'miss')
                sweetMsg('info', 'Blog',
                'Complete información de blog para eliminación');
            else if (body.msg === 'blog-not-deleted')
                sweetMsg('warning', 'Blog',
                'Blog no fue eliminado');
            else if (body.msg === 'rows-not-deleted')
                sweetMsg('warning', 'Blog no eliminado',
                'Filas de contenido no fueron eliminadas');
            else if (body.msg === 'blocks-not-deleted')
                sweetMsg('warning', 'Blog no eliminado',
                'Bloques de contenido no fueron eliminadas');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Blog',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Blog',
                'Recargue la página');
        }
    } catch (err) {
        sweetMsg('error', 'Blog',
        'No se pudo eliminar el blog');
    };
};